import React from 'react';
import { FaAngleRight } from "react-icons/fa";
import { MdWatchLater } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { TbMailFilled } from "react-icons/tb";
import { BiSolidPhoneCall } from "react-icons/bi";

const Footer = () => {
    return (
        <>
            <div className='footer-bg'>
                <div style={{ backgroundColor: "rgba(41, 69, 33,0.8)" }}>
                    <div className="container">
                        <div className="row text-light">
                            <div className="col-xl-3 col-lg-2  ">
                                <div className="footer-logo ">
                                    <img src="assets/logo.png" alt="" />

                                </div>
                                <p className='footer-quote '>The path to great health in Ayurveda lies in the simplicity of two steps: <span style={{ color: "#B2BE59" }}>doing less, being more</span>.</p>
                            </div>
                            <div className="col-xl-9 col-lg-10 ">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-3 py-2 col-md-6 d-md-flex justify-content-center justify-content-md-start my-md-0 my-3">
                                        <div className='footer-links'>
                                            <h5>Quick Links</h5>
                                            <div><FaAngleRight className='footer-link-icon' /><a href=''>Home</a></div>
                                            <div><FaAngleRight className='footer-link-icon' /><a href='#about'>About Us</a></div>
                                            <div><FaAngleRight className='footer-link-icon' /><a href="#services">Services</a></div>
                                            <div><FaAngleRight className='footer-link-icon' /><a href="#WhyChooseUs">Why Choose Us</a></div>
                                            <div><FaAngleRight className='footer-link-icon' /><a href="#Testimonial">Testimonial</a></div>
                                            <div><FaAngleRight className='footer-link-icon' /><a href="#Contact">Contact Us</a></div>
                                            <div className='book-appointment-footer'>
                                                <a href="#Appointment" className='btn'>Book An Appointment</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-5 py-2 col-md-6 d-md-flex justify-content-center justify-content-md-start my-md-0 my-3">
                                        <div className="footer-links">
                                            <h5>Store Timings</h5>
                                            <div><MdWatchLater className='footer-link-icon' />Mon-Sat : 9:30am - 1:30pm</div>
                                            <div><MdWatchLater className='footer-link-icon' />Mon-Sat : 4:00pm - 9:00pm</div>
                                            <div><MdWatchLater className='footer-link-icon' />Sunday : Closed</div>
                                            <h5 className='mt-3'>Clinic Timings</h5>
                                            <div><MdWatchLater className='footer-link-icon' />Mon-Sat : 10:30am - 12:30pm</div>
                                            <div><MdWatchLater className='footer-link-icon' />Mon-Sat : 5:30pm - 7:30pm</div>
                                            <div><MdWatchLater className='footer-link-icon' />Sunday : Closed</div>

                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-4 py-2 col-md-6 d-md-flex justify-content-center justify-content-md-start my-md-0 my-3">
                                        <div className="footer-links">
                                            <h5>Get In Touch</h5>
                                            <div className='d-flex'>
                                                <div><FaLocationDot className='footer-link-icon mt-2' /></div>
                                                <div >Vikalang Karmchari Sahkari Society-8, Opp. Janta Dairy, Near Raiya Chokdi,
                                                    Raiya Road Rajkot - 360007</div>
                                            </div>
                                            <div className='d-flex'>
                                                <div><TbMailFilled className='footer-link-icon' /></div>
                                                <a href="mailto:geetanjaliaushadhaalay@gmail.com@gmail.com">geetanjaliaushadhaalay@gmail.com</a>
                                            </div>
                                            <div className='d-flex'>
                                                <div><BiSolidPhoneCall className='footer-link-icon' /></div>
                                                <a href="tel:7016285437">70162-85437</a> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mini-footer text-light">
                <span>Copyright © {(new Date().getFullYear())} Geetanjali Aushadhaalay, All rights reserved.</span>
            </div>
        </>
    )
}

export default Footer
