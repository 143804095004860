import React from 'react';
import { BiSolidQuoteRight } from "react-icons/bi";
import Slider from "react-slick";

const Testimonials = () => {
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div id='Testimonial'>
            <div className="container" data-aos="zoom-out" data-aos-duration="1500">
                <div className='tesimonial-bg'>
                    <div style={{ background: 'rgba(145, 204, 161,0.4)', minHeight: "20rem" }}>
                        <div className="row testimonial justify-content-center">
                            <div className="col-lg-6"></div>
                            {/* <div className="col-lg-6 text-center">
                                <div className="t-icon"><BiSolidQuoteRight /></div>
                                <div className="slider-container t-slider py-3">
                                    <Slider {...settings}>
                                        <div>
                                            <h6>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eius, delectus nisi. Facilis similique neque asperiores.</h6>
                                            <p className='py-2'>Geetanjali Aushadhaalay</p>
                                        </div>
                                        <div>
                                            <h6>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure perferendis nobis enim aspernatur ipsum vitae modi fugit expedita atque. Nam!</h6>
                                            <p className='py-2'>Geetanjali Aushadhaalay</p>
                                        </div>
                                        <div>
                                            <h6>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum facere et, tenetur, provident fugit dolore nisi blanditiis magni quia maiores ipsa quidem!</h6>
                                            <p className='py-2'>Geetanjali Aushadhaalay</p>
                                        </div>
                                        <div>
                                            <h6>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum error accusamus quaerat. Sunt, vitae magni!</h6>
                                            <p className='py-2'>Geetanjali Aushadhaalay</p>
                                        </div>

                                    </Slider>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonials
