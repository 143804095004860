import './App.css';
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from './components/Home';
import Header from './components/Header';
import About from './components/About';
import { useEffect } from 'react';
import Quote from './components/Quote';
import Services from './components/Services';
import Appointment from './components/Appointment';
import WhyChooseUs from './components/WhyChooseUs';
import Brands from './components/Brands';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import Contact from './components/Contact';

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <Header />
      <Home />
      <About />
      <Quote />
      <Services />
      <Appointment />
      <WhyChooseUs />
      <Brands />
      <Testimonials />
      <Contact/>
      <Footer />
    </>
  );
}

export default App;
