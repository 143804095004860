import React from 'react';
import Slider from "react-slick";

const Brands = () => {
    var settings = {
        dots: false,
        infinite: true,
        // speed: 500,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        slidesToShow: 4,
        // slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,

                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="container py-3">
            <div className="row align-items-center">
                <div className="col-lg-3 col-md-4 col-6  brand-title">
                    <h3>Associated With :</h3>
                </div>
                <div className="col-lg-9 col-md-8 col-6">
                    <div className="slider-container">
                        <Slider {...settings}>
                            <div>
                                <img src="assets/brands/logo1.png" alt="" />
                            </div>
                            <div>
                                <img src="assets/brands/logo2.png" alt="" />
                            </div>
                            <div>
                                <img src="assets/brands/logo3.png" alt="" />
                            </div>
                            <div>
                                <img src="assets/brands/logo4.png" alt="" />
                            </div>
                            <div>
                                <img src="assets/brands/logo5.png" alt="" />
                            </div>

                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Brands
