import React from 'react';
import { BiSolidMessage } from "react-icons/bi";
import { ImArrowUpRight } from "react-icons/im";
import { GiThreeLeaves } from "react-icons/gi";
import { PiFlowerLotusDuotone } from "react-icons/pi";
import { FaLayerGroup } from "react-icons/fa6";
import { GiMeditation } from "react-icons/gi";

const Services = () => {
    return (
        <div id='services'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 p-2  services-title">
                        <div>
                            <div className="section-title">SERVICES</div>
                            <h1 data-aos="fade-up" data-aos-duration="1500">A holistic journey to harmony</h1>
                            <p data-aos="zoom-in" data-aos-duration="1500">Nature provides us with an abundance of healing food. Accept them and let nature be your medicine.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 p-2 ">
                        <div className='services' data-aos="fade-left" data-aos-duration="1000" data-bs-toggle="modal" data-bs-target="#Consultation">
                            <div className="service-logo">
                                <BiSolidMessage />
                            </div>
                            <h3>Consultation</h3>
                            <p className='modernWay'>Ayurvedic practitioner conduct thorough consaltations to assess your body's dosh & dhatu and find out the root cause of the disease.Patients are advised medicinal & therapeutic treatments accordingly.</p>
                           
                            <div className="service-arrow" data-bs-toggle="modal" data-bs-target="#Consultation">
                                <ImArrowUpRight />
                            </div>
                        </div>
                        <div class="modal fade" id="Consultation" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title fw-bold" style={{ color: "#509561" }} id="exampleModalLabel">Consultation</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <p>Ayurvedic practitioner conduct thorough consaltations to assess your body's dosh & dhatu and find out the root cause of the disease.Patients are advised medicinal & therapeutic treatments accordingly.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 p-2 ">
                        <div className='services' data-aos="fade-left" data-aos-duration="2000" data-bs-toggle="modal" data-bs-target="#Basti">
                            <div className="service-logo">
                                <GiThreeLeaves />
                            </div>
                            <h3>Basti</h3>
                            <p className='modernWay'>Basti is a specialized panchkarm treatment that is highly effective in the treatments of "vat vyadhis" (osteoarthritis etc).In this procedure, Herbal decoctions or medicated oils are administered via the anal route.</p>

                            <div className="service-arrow" data-bs-toggle="modal" data-bs-target="#Basti">
                                <ImArrowUpRight />
                            </div>
                        </div>
                        <div class="modal fade" id="Basti" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title fw-bold" style={{ color: "#509561" }} id="exampleModalLabel">Basti</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <p>Basti is a specialized panchkarm treatment that is highly effective in the treatments of "vat vyadhis" (osteoarthritis etc).In this procedure, Herbal decoctions or medicated oils are administered via the anal route.</p>
                                        <h6 style={{ color: "#509561" }}>Benefits</h6>
                                        <ul className='benefits'>
                                            <li>Helps in balancing the 3 doshas</li>
                                            <li> Enhances absorption & digestion</li>
                                            <li>Relieves constipation</li>
                                            <li> Strengthens and nourishes the tissues</li>
                                            <li>Enhances blood circulations</li>
                                            <li>Boosts immunity</li>
                                            <li>Relieves vata-related related joint problems</li>
                                            <li>Helps imbalance in hormonal</li>
                                            <li>Improves the texture & tone of the skin</li>
                                            <li>Removes the accumulated toxins from the colon</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-4 col-md-6 p-2 ">
                        <div className='services' data-aos="fade-up" data-aos-duration="1000" data-bs-toggle="modal" data-bs-target="#Nasya">
                            <div className="service-logo">
                                <PiFlowerLotusDuotone />
                            </div>
                            <h3>Nasya</h3>
                            <p className='modernWay'>Nasyakarm is a powerful procedure of panchkarm. It is extremely beneficial treatment for a wide range of health conditions like migraine, allergic conditions, respiratory disorders, Hairfall etc... <br />
                                In this procedure the medicated oil or powder is administered into the patient's nostrils using a dropper or a nasya pot</p>
                            {/* <button type="button" class="read-more-btn" data-bs-toggle="modal" data-bs-target="#Nasya">
                                Read More
                            </button> */}
                            <div className="service-arrow" data-bs-toggle="modal" data-bs-target="#Nasya">
                                <ImArrowUpRight />
                            </div>
                        </div>
                        <div class="modal fade" id="Nasya" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title fw-bold" style={{ color: "#509561" }} id="exampleModalLabel">Nasya</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <p>Nasyakarm is a powerful procedure of panchkarm.It is extremely beneficial treatment for a wide range of health conditions like migraine, allergic conditions, respiratory disorders, Hairfall etc... <br />
                                            In this procedure the medicated oil or powder is administered into the patient's nostrils using a dropper or a nasya pot</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 p-2 ">
                        <div className='services' data-aos="fade-up" data-aos-duration="2000" data-bs-toggle="modal" data-bs-target="#Akshitarpan">
                            <div className="service-logo">
                                <FaLayerGroup />
                            </div>
                            <h3>Akshitarpan</h3>
                            <p className='modernWay'> Akshi means "eye" & tarpan means "nourishment".Akshitarpan is a curative & preventive therapy for maintaining the healthy normal condition of eyes.In this procedure the patient is instructed to keep the eyes closed where as the medicated ghea is being poured into the ring.later patient is asked to open & close the eyes slowly intermittently.
                            </p>
                            {/* <button type="button" class="read-more-btn" data-bs-toggle="modal" data-bs-target="#Akshitarpan">
                                Read More
                            </button> */}
                            <div className="service-arrow " data-bs-toggle="modal" data-bs-target="#Akshitarpan">
                                <ImArrowUpRight />
                            </div>
                        </div>
                        <div class="modal fade" id="Akshitarpan" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title fw-bold" style={{ color: "#509561" }} id="exampleModalLabel">Akshitarpan</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <p>Akshi means "eye" & tarpan means "nourishment".Akshitarpan is a curative & preventive therapy for maintaining the healthy normal condition of eyes.In this procedure the patient is instructed to keep the eyes closed where as the medicated ghea is being poured into the ring.later patient is asked to open & close the eyes slowly intermittently.</p>
                                        <h6 style={{ color: "#509561" }}>Benefits</h6>
                                        <ul className='benefits'>
                                            <li>This is an excellent treatment taht relieves tiredness </li>
                                            <li>Improves vision eyesight</li>
                                            <li>Moisturizes the dry eyes</li>
                                            <li>It gives strength to the eyes</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 p-2 ">
                        <div className='services' data-aos="fade-up" data-aos-duration="3000" data-bs-toggle="modal" data-bs-target="#Shirodhara">
                            <div className="service-logo">
                                <GiMeditation />
                            </div>
                            <h3 >Shirodhara</h3>
                            <p className='modernWay'>shirodhara is an ayurvedic healing procedure it involves the application of medicated oil on the patient's forehead.This unique procedure activates patient's third eye. Induces a relax state of mind & that results in a psychosomatic balance.It improves sleep, concartration, boosts blood circulation , nourishes hair & scalp,helps in hairfall, dandruff,headache,reduces stress, excessive thinking , anexietly stabilized mood ,feeling of well being & happiness.
                            </p>
                            {/* <button type="button" class="read-more-btn" data-bs-toggle="modal" data-bs-target="#Shirodhara">
                                Read More
                            </button> */}
                            <div className="service-arrow" data-bs-toggle="modal" data-bs-target="#Shirodhara">
                                <ImArrowUpRight />
                            </div>
                        </div>
                        <div class="modal fade" id="Shirodhara" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title fw-bold" style={{ color: "#509561" }} id="exampleModalLabel">Shirodhara</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <p>shirodhara is an ayurvedic healing procedure it involves the application of medicated oil on the patient's forehead.This unique procedure activates patient's third eye. Induces a relax state of mind & that results in a psychosomatic balance.It improves sleep, concartration, boosts blood circulation , nourishes hair & scalp,helps in hairfall, dandruff,headache,reduces stress, excessive thinking , anexietly stabilized mood ,feeling of well being & happiness.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Services
