import React from 'react'

const Home = () => {
  return (
    <div>
      <div className='banner overlay'>
        {/* <div style={{ background: "linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147))", height: "100%", opacity: "0.4" }}> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className='home-content' >
                <h1 data-aos="fade-right" data-aos-duration="1500">An Ancient Key For Total Wellbeing</h1>
                <p data-aos="zoom-in" data-aos-duration="1500">Ayurveda is a holistic lifestyle system that teaches the practical details of the arrangement of food, body work, rest periods, and work, which aims to achieve balance of body,mind, and soul.</p>
              </div>
            </div>
            <div className="col-lg-5"></div>
          </div>

        </div>
        {/* </div> */}
      </div>
    </div>
  )
}

export default Home
