import React, { useEffect } from 'react';
import { IoCallSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';

const Header = () => {
    const isSticky = (e) => {
        const header = document.querySelector('#sticky-nav');
        const scrollTop = window.scrollY;
        scrollTop >= 100 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };
    // Sticky Menu Area
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    return (
        <>
            <div className='header' >
                <div id="sticky-nav">

                    <div className="row">
                        <div className="col-xl-10 col-lg-11 col-10 p-0" style={{ borderLeft: "3px solid #70c285" }}>
                            <nav class="navbar navbar-expand-lg navbar-light bg-light header-nav p-0">
                                <div class="container-fluid py-1 px-3 ">
                                    <a class="navbar-brand header-logo" href="/"><img src="assets/logo.png" alt="" style={{ height: "5rem" }} /></a>
                                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span class="navbar-toggler-icon"></span>
                                    </button>
                                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul class="navbar-nav me-auto mb-2 mb-lg-0 gap-2">
                                            <li class="nav-item">
                                                <Link class="nav-link active" aria-current="page" to="/">Home</Link>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" href="#about">About Us</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" href="#services">Services</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" href="#Appointment">Appointment</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" href="#WhyChooseUs">Why Choose Us</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" href="#Testimonial">Testimonial</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" href="#Contact">Contact Us</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                        <div className="col-xl-2 col-lg-1 col-2 header-call" >
                            <a href="tel:7016285437" style={{ cursor: "pointer", textDecoration: "none", color: "white"}}>
                                <div className="row align-items-center justify-content-center p-1">
                                    <div className="col-xl-3 header-call-btn"><IoCallSharp /></div>
                                    <div className="col-xl-9 icons d-xl-block d-none ">
                                        <span className='call-us-now '>Call Us Now!</span><br />
                                        <span style={{ fontSize: "20px" }}>70162 85437</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Header
