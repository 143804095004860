import React, { useRef, useState } from 'react'
import { AiOutlineUser } from "react-icons/ai";
import { MdOutlineEmail } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2'
import img from '../logo.png'

const Contact = () => {
    const form = useRef();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [sendMail, setSendMail] = useState('Send Message');

    var templateParams = {
        name: name,
        email: email,
        phone: phone,
        message: message,
        image:img
    };

    const sendEmail = async (e) => {
        e.preventDefault();
        setSendMail('Sending...')
        const sendmail = await emailjs
            .send('service_vszb0y8', 'template_s45zjyf', templateParams, {
                publicKey: '34jFhMj15C-PkL8ss',
            })
            .then(
                (response) => {
                    setSendMail("Submit");
                    setName("");
                    setEmail("");
                    setMessage("");
                    setPhone("");
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Your Email has been send",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    
                },
                (err) => {
                    setName("");
                    setEmail("");
                    setMessage("");
                    setPhone("");
                    setSendMail("Submit");
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        showConfirmButton: false,
                        timer: 1500
                    });
                },
            );


    };

    return (
        <div className='Contact-us' id='Contact'>
            <div className='container'>
                <div className="row">
                    <div className="col-lg-6 p-0">
                        <div className='contact-form'>
                            <h3 className=" mb-3">
                                We'll in touch shortly
                            </h3>
                            <p className="pb-2">Ayurveda provides natural and consistent treatment to keep the human body healthy.</p>
                         
                            <form ref={form} onSubmit={sendEmail}>
                                <div className='Contact-field my-2 px-2' data-aos="fade-right" data-aos-duration="2000">
                                    <AiOutlineUser className='field-icon' />
                                    <input type="text" placeholder='Name' id="name" value={name} onChange={(e) => { setName(e.target.value) }} />
                                </div>

                                <div className="row">
                                    <div className="col-md-6" data-aos="fade-right" data-aos-duration="2000">
                                        <div className='Contact-field my-2 px-2'>
                                            <MdOutlineEmail className='field-icon' />
                                            <input type="email" placeholder='Email' id="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6" data-aos="fade-right" data-aos-duration="2000">
                                        <div className='Contact-field my-2 px-2'>
                                            <BsTelephone className='field-icon' />
                                            <input type="number"
                                                placeholder='Phone No'
                                                id="phone" min={10}
                                                onChange={event => {
                                                    const limit = 10;
                                                    setPhone(event.target.value.slice(0, limit));
                                                }}
                                                onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                                                value={phone}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='Contact-field my-2 p-2' data-aos="fade-right" data-aos-duration="2000" >
                                    <textarea placeholder='Your Message' rows="5" id="message" value={message} onChange={(e) => { setMessage(e.target.value) }} />
                                </div>

                                <div className="contact-btn my-2" data-aos="fade-right" data-aos-duration="2000">
                                    <button type="submit" className="btn olive" value="Send" >{sendMail}</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="col-lg-6 p-0 py-3" data-aos="fade-left" data-aos-duration="1500">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.3990914645265!2d70.75798569027472!3d22.300741488540087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959c97e7f6fe759%3A0x9cf5344443a16ccc!2sRaiya%20Rd%2C%20Near%20Raiya%20Chowkdi%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1711688172673!5m2!1sen!2sin" style={{ width: "100%", height: "100%" }} title='geetanjali Aushadhaalay'></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
