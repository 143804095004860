import React from 'react';
import { FaCheck } from "react-icons/fa";

const WhyChooseUs = () => {
    return (

        <div className="container" id='WhyChooseUs'>
            <div className="row">
                <div className="col-lg-5 d-flex flex-column gap-3">
                    <div className="section-title">WHY CHOOSE US</div>
                    <h1 >Ayurved is a holistic science of life</h1>
                    <p>Ayurved is an indigenous ancient medical science of india.It is considered to be an "upved" of "atharvved". <span className='data-read' data-bs-toggle="modal" data-bs-target="#WhyChooseUsDetails">Read More</span> </p>
                    {/* <button type="button" class="read-more-btn" data-bs-toggle="modal" data-bs-target="#WhyChooseUsDetails">
                        Read More
                    </button> */}
                    <div class="modal fade" id="WhyChooseUsDetails" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title fw-bold" style={{ color: "#509561" }} id="exampleModalLabel">Why Choose Us</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <h5 className='my-3 px-md-3 text-center text-capitalize' style={{color:"#a1847f"}}>Ayurved is an indigenous ancient medical science of india.It is considered to be an "upved" of "atharv ved"</h5>
                                    <ul className='benefits'>
                                            <li>Ayurved is a holistic science of life.</li>
                                            <li>प्रयोजनम् चास्थ स्वस्थस्य स्वास्थ्य रक्षणमातुरस्थ विकार प्रशमनं च ॥ <br />
                                            Means- its aim is to maintain the health of healthy indivisual and to treat the disease of sick indivisual.
                                            </li>
                                            <li>There are many health maintaining principles are desscribed in ayurved like "dincharya" & "ritucharya"</li>
                                            <li>today  the lifestyle & diet routine has been changed by people.these basic principles of maintaining healthy life are not followed by people and the number of people affected by lifestyle disorders is increasing day by day.</li>
                                            <li>We strongly beliave that most lifestyle disorders or any other disease or symptoms can be preverted by adopting the principals of ayurved.</li>
                                            <li>In geetanjali aushadhaalay the physical and mental condition of every patient is assessed & treated by authentic classical medicines & therapies by the doctor.</li>
                                            <li>Medicines are prescribed to the patient according to thair "prakruti" and advise the changes in dincharya & ritucharya according to ayurved.</li>
                                            <li><span className='fw-bold'>Dincharya</span> is a daily routine that promotes selfcare throught difference daily activities by bringing us in treat  with nature. It regularizes over biological clock and makes as more selfaware.</li>
                                            <li><span className='fw-bold'>Ritucharya</span> consists of lifestyle & ayurvedic diet routine to cope with the body & mental impacts caused by seasnal changes as recommenated by ayurved.</li>
                                        </ul>
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="d-flex gap-2" data-aos="fade-right" data-aos-duration="1500">
                        <div className="whyChoose-check">
                            <FaCheck />
                        </div>
                        <div className="whyChoose-points">
                            <h6>Dincharya</h6>
                            <p>Dincharya is a daily routine that promotes selfcare throught difference daily activities by bringing us in treat  with nature. It regularizes over biological clock and makes as more selfaware.</p>
                        </div>
                    </div>
                    <div className="d-flex gap-2" data-aos="fade-left" data-aos-duration="1500">
                        <div className="whyChoose-check">
                            <FaCheck />
                        </div>
                        <div className="whyChoose-points">
                            <h6>Ritucharya</h6>
                            <p>Ritucharya consists of lifestyle & ayurvedic diet routine to cope with the body & mental impacts caused by seasnal changes as recommenated by ayurved.</p>

                            {/* <p>today  the lifestyle & diet routine has been changed by people.these basic principles of maintaining healthy life are not followed by people and the number of people affected by lifestyle disorders is increasing day by day.</p>

                            <p>We strongly beliave that most lifestyle disorders or any other disease or symptoms can be preverted by adopting the principals of ayurved.</p>

                            <p>In geetanjali aushadhaalay the physical and mental condition of every patient is assessed & treated by authentic classical medicines & therapies by the doctor.</p>

                            <p>Medicines are prescribed to the patient according to thair "prakruti" and advise the changes in dincharya & ritucharya according to ayurved.</p> */}
                        </div>
                    </div>
                </div>

                <div className="col-lg-7">
                    <div className='position-relative whyCooseUs'>
                        <img data-aos="zoom-out-up" data-aos-duration="1500" src="assets/img2.jpg" alt="" />
                        <div className='img-content'
                            data-aos="fade-down"
                            data-aos-easing="linear"
                            data-aos-duration="1500">
                            <div className="content-border">
                                <h6>"We improve your health, and teach you how to stay healthy."</h6>
                                <p className='mb-0'>STEVEN SMITH</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyChooseUs
