import React from 'react'

const Quote = () => {
    return (
        <div>
            <div style={{ background: "url(assets/banner2.jpg) no-repeat center/cover", backgroundAttachment: "fixed" }}>
                <div style={{ background: 'rgba(185, 240, 158,0.5)' }}>
                    <div className="container ">
                        <div className="row">
                            <div className="col-lg-12 quote-content text-center">
                                <h1 data-aos="fade-up"
                                    data-aos-anchor-placement="top-bottom"
                                    data-aos-duration="1500">"Ayurveda teaches us to live in harmony with ourselves, others, and the environment to achieve true well-being."</h1>
                                <h5> - Ayurvedic principle</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Quote
