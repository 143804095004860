import React, { useRef, useState } from 'react'
import { AiOutlineUser } from "react-icons/ai";
import { MdOutlineEmail } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2'
import { FaHandPointRight } from "react-icons/fa6";

const Appointment = () => {
    const form = useRef();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [sendMail, setSendMail] = useState('Submit');

    var templateParams = {
        name: name,
        email: email,
        phone: phone,
        message: message
    };

    const sendEmail = async (e) => {
        e.preventDefault();
        setSendMail('Sending...')
        const sendmail = await emailjs
            .send('service_vjhi5fk', 'template_h6fxtxw', templateParams, {
                publicKey: 'fY6rDJKt4veiqbw9z',
            })
            .then(
                (response) => {
                    setSendMail("Submit");
                    setName("");
                    setEmail("");
                    setMessage("");
                    setPhone("");
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Your Email has been send",
                        showConfirmButton: false,
                        timer: 1500
                    });

                },
                (err) => {
                    setName("");
                    setEmail("");
                    setMessage("");
                    setPhone("");
                    setSendMail("Submit");
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        showConfirmButton: false,
                        timer: 1500
                    });
                },
            );


    };

    return (
        <div id='Appointment'>
            <div style={{ background: "url(assets/banner3.jpg) no-repeat center/cover", backgroundAttachment: "fixed" }}>
                <div style={{ background: 'rgba(17, 17, 17,0.7)' }}>
                    <div className="container">
                        <div className='aapointment ' data-aos="fade-up" data-aos-duration="1500">
                            <h6 className="section-title text-center">AYURVEDA TREATMENTS</h6>
                            <h1 className='text-center'>Get Authentic Ayurvedic Treatment Today!</h1>
                            <div className="row justify-content-md-center">
                                <div className="col-lg-4 " style={{ width: "fit-content" }}>
                                    <ul className='treatements mb-0'>
                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>Sciatica</p>
                                        </div>
                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>Osteoarthritis</p>
                                        </div>
                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>Rheumatoid arthritis</p>
                                        </div>
                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>Back pain</p>
                                        </div>

                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>Headache</p>
                                        </div>
                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>Acidity</p>
                                        </div>
                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>Indigestion</p>
                                        </div>
                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>Gastritis</p>
                                        </div>
                                    </ul>
                                </div>
                                <div className="col-lg-4" style={{ width: "fit-content" }}>
                                    <ul className='treatements mb-0'>

                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>Ulcers</p>
                                        </div>
                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>UTI</p>
                                        </div>
                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>Renal stone</p>
                                        </div>
                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>Urine disorders</p>
                                        </div>
                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>Chronic constipation</p>
                                        </div>
                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>Diabetes</p>
                                        </div>
                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>Seasonal infections</p>
                                        </div>
                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>Skin diseases</p>
                                        </div>
                                    </ul>
                                </div>
                                <div className="col-lg-4" style={{ width: "fit-content" }}>
                                    <ul className='treatements'>

                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>Acne pimple</p>
                                        </div>
                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>PCOD</p>
                                        </div>
                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>Menses related problems</p>
                                        </div>
                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>Obesity</p>
                                        </div>
                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>Asthma</p>
                                        </div>
                                        <div className='treatement-list'>
                                            <div className='list-img'><FaHandPointRight /></div>
                                            <p>Respiratory diseases Chronic cough</p>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                {/* <div className='call-btn'>
                                    <a href="tel:7802017131">Consultation On Call</a>
                                </div> */}
                                <div className='book-btn'>
                                    <button data-bs-toggle="modal" data-bs-target="#exampleModal">Book An Appointment</button>
                                </div>
                            </div>
                        </div>
                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title fw-bold" id="exampleModalLabel">Book an appointment</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <form ref={form} onSubmit={sendEmail}>
                                        <div class="modal-body px-md-4">
                                            <p className=''>Fill the form & get authentic ayurvedic treatment today!</p>

                                            <div className='Contact-field my-2 px-2'>
                                                <AiOutlineUser className='field-icon' />
                                                <input type="text" placeholder='Name' id="name" name="name" value={name} onChange={(e) => { setName(e.target.value) }} />
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6" >
                                                    <div className='Contact-field my-2 px-2'>
                                                        <MdOutlineEmail className='field-icon' />
                                                        <input type="email" placeholder='Email' id="email" name="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className='Contact-field my-2 px-2'>
                                                        <BsTelephone className='field-icon' />
                                                        <input type="number"
                                                            placeholder='Phone No'
                                                            id="phone" min={10}
                                                            name="phone"
                                                            onChange={event => {
                                                                const limit = 10;
                                                                setPhone(event.target.value.slice(0, limit));
                                                            }}
                                                            onKeyDown={e => (e.key === "e" || e.key === "-" || e.key === "+" || e.key === ".") && e.preventDefault()}
                                                            value={phone}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='Contact-field my-2 p-2' >
                                                <textarea placeholder='Your Message' rows="5" name="message" id="message" value={message} onChange={(e) => { setMessage(e.target.value) }} />
                                            </div>

                                        </div>
                                        <div class="modal-footer appointment-btn d-flex justify-content-md-end justify-content-center">
                                            <button type="submit" className="btn submit-btn" value="Send" >{sendMail}</button>
                                            <button type="button" className="btn cancle-btn" data-bs-dismiss="modal">Close</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Appointment
