import React from 'react';
import { FaCheck } from "react-icons/fa";

const About = () => {

    return (
        <div id='about'>
            <div className='container'>
                <div className="row align-items-center">
                    <div className="col-lg-6 position-relative">
                        <div className='about-imgs '>
                            <div className="row ">
                                <div className="col-6">
                                    <img data-aos="fade-up" data-aos-duration="1500" src="assets/about1.jpg" alt="" />
                                </div>
                                <div className="col-6">
                                    <img data-aos="fade-down" data-aos-duration="1500" src="assets/about2.jpg" alt="" />
                                </div>
                            </div>
                        </div>

                        <div className='about-logo'>
                            <img src="assets/logo.png" alt="" />
                        </div>

                    </div>
                    <div className="col-lg-6 d-flex align-items-center" data-aos="fade-left" data-aos-duration="1500">
                        <div className='about-content'>
                            <h6 className='section-title' style={{}}>ABOUT US</h6>
                            <h1>The natural way to achieving balance and optimal health</h1>
                            {/* <h1>The natural way to achieving balance and optimal health</h1> */}
                          
                            <div className='row'>
                                <div className='col-md-12 py-2 d-flex'>
                                    <div><FaCheck className='about-icon' /></div><span className='ps-2 about-icon-content'>Geetanjali Aushadhaalay (an ayurved panchkarm clinic & an ayurved medicines retail store) is a leading healthcare center active in Rajkot from last few years.</span>
                                </div>
                                <div className='col-md-12 py-2 d-flex'>
                                    <div><FaCheck className='about-icon' /></div><span className='ps-2 about-icon-content'>Every patient is treated authentic classical medicines a proper diet & life style changes suggestions according to the disease by well experienced doctor.</span>
                                </div>
                                <div className='col-md-12 py-2 d-flex'>
                                    <div><FaCheck className='about-icon' /></div><span className='ps-2 about-icon-content'>We strictly adhere to the traditional principles of Ayurved.</span>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
